import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import LoginView from '../views/LoginView.vue'
import SetupJwtFromHook from '@/components/SetupJwtFromHook'
import ServicesView from '../views/ServicesView.vue'
import EtsySubscription from '@/components/Services/EtsySubscription'
import ServiceHome from '@/components/Services/ServicesHome'
import Profile from '@/components/Profile'

import { VueEasyJwt } from "vue-easy-jwt";
const jwt = new VueEasyJwt();

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: Home
  },
  /*
    {
      path: '/services/:id?', // :id
      name: 'fuck',
      meta: {
        requiresAuth: true,
      },
      component: ServicesView
  
  
    },*/
  ,
  {
    path: '/services',
    component: ServicesView,
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        component: ServiceHome
      },
      /*
      {
        path: 'etsy/:id?',
        component: EtsySubscription
      },*/
      {
        path: 'profile',
        component: Profile
      },
      {
        path: ':id',
        component: EtsySubscription
      },

    ]
  },
  /* */
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/login/etsy/:jwt',
    name: 'SetupJwtFromHook',
    component: SetupJwtFromHook
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// https://www.npmjs.com/package/vue-easy-jwt
router.beforeEach((to, from, next) => {
  to.matched.some((route) => {
    if (route.meta.requiresAuth) {
      // import your token
      const yourToken = localStorage.getItem("token");

      // returns true if is expired
      // if it is an empty string, null or undefined
      // will return true (expired)
      if (jwt.isExpired(yourToken)) {
        // if is expired the user should sign in again
        next({ path: "/login" });
      }
    }

    next();
  });
});

export default router