<template>
  <div>
    <LoginEtsy />
  </div>
</template>

<script>
import LoginEtsy from '@/components/LoginWithEtsy'

export default {
  name: 'LoginView',
  components: { LoginEtsy }
}
</script>
