<template>
  <div>
    <h1 class="title">Servizi</h1>
    <section class="services-container">
      <div class="container">
        <div class="columns is-mobile">
          <div class="column sidebar">
            <aside class="menu has-text-left">
              <p class="menu-label">Services</p>
              <ul class="menu-list">
                <li
                  v-for="(s, k) in subscriptions"
                  v-bind:key="`subscription_${s.id}_${k}`"
                >
                  <router-link :to="`/services/${s.Id}/`" replace>{{
                    s.Title
                  }}</router-link>
                </li>
                <li>
                  <router-link to="/services/profile">Profile</router-link>
                </li>
              </ul>
            </aside>
          </div>
          <div class="column service-content">
            <!-- route: {{ $route.params }} -->
            <router-view />

            <pre class="has-text-left">{{ currentSubscription }}</pre>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import EtsySubscription from "@/components/Services/EtsySubscription";

export default {
  name: "ServicesView",
  components: { EtsySubscription },
  data() {
    return {
      userToken: this.$jwt.getToken(),
      subscriptions: Object,
      currentSubscription: null,
    };
  },

  methods: {
    decodeToken() {
      const decodedToken = this.$jwt.decodeToken(this.userToken);
      this.subscriptions = decodedToken.subscriptions;

      console.log(decodedToken);
    },
  },
  mounted() {
    this.decodeToken();
    // this.$route.params.id

    this.currentSubscription = this.subscriptions.find(
      (s) => s.Id === this.$route.params.id // FIXME: Capitalized prop
    );
  },
  watch: {
    $route(to, from) {
      // react to route changes...
      console.info("FROM:", from);
      console.info("TO:", to);

      //window.location.reload()
    },
  },
};
</script>