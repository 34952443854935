<template>
  <section>
    <h2 class="sub-title"># Filtro ordini</h2>

    <b-field>
      <b-select v-model="search_filter.sent" placeholder="Sent?">
        <option :value="null">Any</option>
        <option :value="true" selected>Sent</option>
        <option :value="false">Not sent</option>
      </b-select>

      <b-select v-model="search_filter.paid" placeholder="Paid?">
        <option :value="null">Any</option>
        <option :value="true" selected>Paid</option>
        <option :value="false">Unpaid</option>
      </b-select>

      <b-datepicker
        v-model="search_filter.from"
        expanded
        placeholder="From date"
        required
      />
      <b-tooltip label="Data compresa">
        <b-datepicker
          v-model="search_filter.to"
          expanded
          placeholder="To date"
          required
        />
      </b-tooltip>
      <b-select
        v-model="search_filter.page_size"
        placeholder="Results per page"
      >
        <option value="25">25</option>
        <option value="50" selected>50</option>
        <option value="100">100</option>
      </b-select>

      <p class="control">
        <b-button
          v-on:click="loadAsyncData"
          type="is-primary"
          :loading="loading"
          >Search</b-button
        >
      </p>
    </b-field>

    <b-field>
      <b-button
        type="is-link"
        native-type="submit"
        :disabled="loading || !checked_rows.length"
        :loading="loading"
        v-on:click="requestInvoices"
        >Genera fatture selezionate</b-button
      >
    </b-field>
    <pre class="has-text-left">{{ search_filter }}</pre>

    <b-notification
      type="is-warning"
      aria-close-label="Close notification"
      role="alert"
    >
      Parti dall'ultima pagina della ricerca. La numerazione viene impostata
      progressivamente a questa visualizzazione
    </b-notification>

    <b-table
      :data="data"
      :loading="loading"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
      :checked-rows.sync="checked_rows"
      striped
      checkable
    >
      <b-table-column field="buyer" label="Buyer" v-slot="props">
        {{ props.row.name }}
      </b-table-column>

      <b-table-column
        field="payment_method"
        label="Payment method"
        v-slot="props"
      >
        {{ props.row.payment_method }}
      </b-table-column>

      <b-table-column
        field="adjusted_grandtotal"
        label="Total"
        numeric
        v-slot="props"
      >
        {{ props.row.adjusted_grandtotal }} {{ props.row.currency_code }}
      </b-table-column>

      <b-table-column field="date" label="Date" centered v-slot="props">
        {{ props.row.creation_tsz | date }}
      </b-table-column>

      <b-table-column v-slot="props">
        <!--
        <b-button type="is-success" icon-right="content-duplicate" outlined />
        <b-button type="is-success" icon-right="download" outlined />
        -->
      </b-table-column>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="reorder-horizontal" size="is-large"></b-icon>
            </p>
            <p>Nessun dato da visualizzare</p>
          </div>
        </section>
      </template>
    </b-table>
  </section>
</template>

<script>
export default {
  data() {
    return {
      // TODO: eredita da parent
      current_subscription: null,
      userToken: this.$jwt.getToken(),

      current_page: 1,
      perPage: 50,
      search_filter: {
        from: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        to: new Date(),
        paid: true,
        sent: null,
        page_size: 50,
      },

      data: [],
      total: 0,
      loading: false,
      sortField: "vote_count",
      sortOrder: "desc",
      defaultSortOrder: "desc",
      checked_rows: [],
    };
  },
  methods: {
    /*
     * Load async data
     */
    loadAsyncData() {
      this.checked_rows = [];

      const params = {
        // this.current_subscription
        subscription_id: this.current_subscription.Id,
        sent: this.search_filter.sent, // <- includi il form della ricerca
        paid: this.search_filter.paid, // <- includi il form della ricerca
        from: this.search_filter.from, // <- includi il form della ricerca
        to: this.search_filter.to, // <- includi il form della ricerca
        offset: 0,
        limit: this.search_filter.page_size,
        page: this.current_page,
      };

      if (params.from > params.to) {
        this.$buefy.dialog.alert({
          type: "is-danger",
          hasIcon: true,
          scroll: "keep",
          message:
            "La data di inizio non può essere maggiore alla data di fine ricerca",
        });
        return;
      }

      this.loading = true;
      this.$http
        .post(`/subscriptions/etsy/search`, params)
        .then(({ data }) => {
          console.warn(data);
          if (!"pagination" in data) throw Error("Expected pagination");

          // api.themoviedb.org manage max 1000 pages
          this.data = [];
          this.total = data.pagination.count;
          this.perPage = data.pagination.page_size;
          this.current_page = data.pagination.current_page;

          if (!data.result) throw Error("Nessun elemento in lista");

          data.result.forEach((item) => {
            item.release_date = item.release_date
              ? item.release_date.replace(/-/g, "/")
              : null;
            this.data.push(item);
          });
          this.loading = false;
        })
        .catch((error) => {
          this.data = [];
          this.total = 0;
          this.loading = false;
          throw error;
        });
    },
    /*
     * Handle page-change event
     */
    onPageChange(page) {
      this.current_page = page;
      this.loadAsyncData();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    /*
     * Type style in relation to the value
     */
    type(value) {
      const number = parseFloat(value);
      if (number < 6) {
        return "is-danger";
      } else if (number >= 6 && number < 8) {
        return "is-warning";
      } else if (number >= 8) {
        return "is-success";
      }
    },
    /*
     * Generate invoices
     */
    requestInvoices() {
      let ids = this.checked_rows.map((a) => a.receipt_id);
      var that = this;
      this.loading = true;

      this.$http
        .post(
          `/subscriptions/etsy/invoices/export`,
          {
            subscription_id: this.current_subscription.Id,
            ids: ids,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Fatture PDF Etsy.zip");
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          that.loading = false;
        });
    },

    decodeToken() {
      const decodedToken = this.$jwt.decodeToken(this.userToken);
      this.subscriptions = decodedToken.subscriptions;
      if (!this.subscriptions) throw Error("Nessuna subscription rilevata");

      this.current_subscription = this.subscriptions[0];

      console.log("current subscription from JWT", this.current_subscription);
    },
  },
  filters: {
    /**
     * Filter to truncate string, accepts a length parameter
     */
    truncate(value, length) {
      return value.length > length ? value.substr(0, length) + "..." : value;
    },
  },
  mounted() {
    this.decodeToken();
    // this.$route.params.id
    //this.current_subscription = this.$route.params.id;
    //this.loadAsyncData();
  },
};
</script>