<template>
  <div>
    <section class="has-text-left mb-6">
      <h1 class="title"># Non c'è niente qui</h1>
      <h2 class="sub-title">seleziona un servizio</h2>
    </section>
  </div>
</template>

<script>
export default {
  name: "ServiceHome",
  data() {
    return {};
  },
};
</script>
