<template>
  <div>Reindirizzamento in corso...</div>
</template>

<script>
import router from "@/router";

// https://github.com/gustavo0197/vue-easy-jwt
export default {
  name: "SetupJwtFromHook",
  data() {
    return {};
  },
  methods: {},
  mounted() {
    var jwt = this.$route.params.jwt;

    localStorage.setItem("token", jwt);

    router.push({ path: "/services" });
    window.location.reload();
  },
};
</script>
