import Vue from 'vue'
import axios from 'axios'
import NProgress from 'nprogress';

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers = {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
};

var token = localStorage.getItem('token');
if (token)
  axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

// before a request is made start the nprogress
axios.interceptors.request.use(config => {
  NProgress.start()
  console.info('ajax request:', config)
  return config
})

// before a response is returned stop nprogress
axios.interceptors.response.use(response => {
  console.info('ajax response:', response)
  NProgress.done()
  return response
})

axios.interceptors.response.use(function (response) {
  console.info(response);
  return response;
}, function (error) {
  console.error("HTTP ERROR:", error);
  return Promise.reject(error);
});

export default axios;