<template>
  <div>
    <section class="has-text-left mb-6">
      <h2 class="sub-title"># Profilo</h2>
    </section>
  </div>
</template>

<script>
export default {
  name: "ServiceEtsy",
  data() {
    return {};
  },
};
</script>
