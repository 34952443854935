<template>
  <div>
    <div class="content">
      <section class="has-text-left mb-6">
        <h2 class="sub-title"># Template</h2>
        <b-button disabled>Apri editor template</b-button>
      </section>

      <section class="has-text-left mb-6">
        <h2 class="sub-title"># Numerazione</h2>
        <form @submit.prevent="updateInvoiceCounter" :disabled="loading">
          La prossima fattura avrà l'id:
          <code>{{ subscription.invoice_counter + 1 }}</code>
          <b-field>
            <b-input
              placeholder="Counter"
              v-model.number="subscription.invoice_counter"
              type="number"
              min="0"
            >
            </b-input>
            <p class="control">
              <b-button
                type="is-primary"
                native-type="submit"
                :loading="loading"
                >Salva</b-button
              >
            </p>
          </b-field>
        </form>
      </section>
    </div>
    <section class="has-text-left mb-6">
      <BackendTableEtsy />
    </section>
  </div>
</template>

<script>
import BackendTableEtsy from "@/components/Services/BackendTableEtsy";

export default {
  name: "ServiceEtsy",
  components: { BackendTableEtsy },
  data() {
    return {
      userToken: this.$jwt.getToken(),
      loading: false,
      subscription: {
        id: 0,
        invoice_counter: 0,
      },
    };
  },
  methods: {
    decodeToken() {
      const decodedToken = this.$jwt.decodeToken(this.userToken);
      this.subscriptions = decodedToken.subscriptions;
      if (!this.subscriptions) throw Error("Nessuna subscription rilevata");

      this.current_subscription = this.subscriptions[0];
    },
    updateInvoiceCounter() {
      var that = this;
      this.loading = true;
      this.$http
        .put(
          `/subscriptions/etsy/${this.subscription.id}/counter/${this.subscription.invoice_counter}`
        )
        .then(({ data }) => {
          that.subscription.invoice_counter = data.result;
        })
        .finally((response) => {
          that.loading = false;
        });
    },

    getSubscription(id) {
      var that = this;
      this.loading = true;

      this.$http
        .get(`/subscriptions/etsy/${id}`)
        .then(({ data }) => {
          that.subscription = data;
        })
        .finally(() => {
          that.loading = false;
        });
    },
  },
  mounted() {
    this.decodeToken();
    var s = this.subscriptions.find(
      (s) => s.Id === this.$route.params.id // FIXME: Capitalized prop
    );

    this.subscription.id = s.Id;
    this.getSubscription(this.$route.params.id);
  },
};
</script>
