import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const dateformat = "DD/MM/YYYY"; // DD/MM/YYYY
const datetimeformat = "DD/MM/YYYY hh:mm"; // DD/MM/YYYY
import moment from "moment";
import vuemoment from "vue-moment"; // disinstalla?
Vue.use(vuemoment);

import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce)

// https://buefy.org/documentation/start
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
Vue.use(Buefy, {
  defaultIconPack: "mdi",
  defaultDateFormatter: date => moment(date).format(dateformat),
  //defaultDateParser: date => moment(date, dateformat).toDate(),
  defaultMonthNames: [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre"
  ],
  defaultDayNames: ["DO", "LU", "MA", "ME", "GI", "VE", "SA"],
  defaultFirstDayOfWeek: 1
});

Vue.filter("date", function (date) {

  if (!isNaN(date)) {
    var length = Math.ceil(Math.log10(date + 1));
    date *= 1000;
  }

  return moment(date).format(dateformat);
});

import "@/styles/main.css";

// https://www.npmjs.com/package/vue-axios
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
import customhttp from '@/router/http'


// JWT TOKEN
import VueEasyJwt from 'vue-easy-jwt'
Vue.use(VueEasyJwt, {
  getToken: () => localStorage.getItem("token")
})

import NProgress from 'vue-nprogress' // https://www.npmjs.com/package/vue-nprogress
Vue.use(NProgress);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
