<template>
  <div>
    <div class="box has-text-centered">
      <h1 class="title">Login con Etsy</h1>

      <a class="button my-4" v-on:click="requestLoginEtsy"
        >Login Etsy
        <!--<img src="@/assets/EtsyLogo.svg" />-->
      </a>

      <div class="is-family-monospace is-size-7">
        <span>The term 'Etsy' is a trademark of Etsy, Inc.</span><br />
        <span
          >This application uses the Etsy API but is not endorsed or certified
          by Etsy, Inc.</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginEtsy",
  data() {
    return {
      info: null,
    };
  },
  methods: {
    requestLoginEtsy() {
      var that = this;
      //authorize/etsy/
      that.$http.get("/authenticate/etsy/url").then((response) => {
        var left = screen.width / 2 - 600 / 2;
        var top = screen.height / 2 - 800 / 2;

        window.location = response.data.login_url;

        // le cose belle non funzionano mai -> modal.onbeforeunload = function (data) { console.info(data);};

        var timer = setInterval(function () {
          if (modal.closed) {
            clearInterval(timer);

            console.warn(modal);
            // ACTION HERE
            //that.authorizeWithEtsy();

            that.$http.post(response.data.o_auth_callback, {}).then((ar) => {
              console.log(response.data);
            });
          }
        }, 333);
      });
    },
    authorizeWithEtsy() {},
  },
  mounted() {
    /*
    this.$http.get(api).then((response) => {
      console.log(response.data)
    })
    */
  },
};
</script>
